import "./Footer.css";

const Footer = () => (
  <div className="footer">
    <p className="author">
      Website created by{" "}
      <a href="https://taylormkaye.com" rel="noreferrer" target="_blank">
        Taylor Kaye
      </a>
    </p>
    <p className="trademark-realtor">
      The trademarks REALTOR®, REALTORS®, and the REALTOR® logo are controlled
      by The Canadian Real Estate Association (CREA) and identify real estate
      professionals who are members of CREA.
    </p>
    <p className="trademark-mls">
      The trademarks MLS®, Multiple Listing Service® and the associated logos
      are owned by The Canadian Real Estate Association (CREA) and identify the
      quality of services provided by real estate professionals who are members
      of CREA.
    </p>
  </div>
);

export default Footer;
