import React, { Component } from 'react';
import { MenuItems } from './MenuItems';
import './Navbar.css';

class Navbar extends Component{
    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked})
    }

    render() {
        return(
            <nav className="nav-items">
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fas fa-times' : 'fa fa-bars'}/>
                </div>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return(
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                    {item.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>
                <span className='nav-socials'>
                    <a href='tel:+1-306-381-8656' className='social-link'><i className='fas fa-phone-alt'/></a>
                    <a href='https://www.instagram.com/erynkaye_/' className='social-link'><i className='fab fa-instagram'/></a>
                    <a href='https://www.facebook.com/3rynkay3' className='social-link'><i className='fab fa-facebook-f'/></a>
                </span>
            </nav>
        )
    }
}

export default Navbar