export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'Brighton Lofts',
        url: '/brighton-lofts',
        cName: 'nav-links'
    },
    // {
    //     title: 'Listings',
    //     url: '/listings',
    //     cName: 'nav-links'
    // },
    {
        title: 'Contact',
        url: '/contact',
        cName: 'nav-links'
    },
]