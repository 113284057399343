import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import "./App.css";
import Footer from "./components/Footer/Footer";

const { PUBLIC_URL } = process.env;

const Home = lazy(() => import("./pages/Home"));
const BrightonLofts = lazy(() => import("./pages/BrightonLofts"));
const Listings = lazy(() => import("./pages/Listings"));
const Contact = lazy(() => import("./pages/Contact"));

export default function App() {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Suspense fallback={<h1> </h1>}>
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/brighton-lofts" component={BrightonLofts} />
            <Route exact path="/listings" component={Listings} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </div>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}
